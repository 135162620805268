import React from "react"
import { Link } from "gatsby"
import Twemoji from 'react-twemoji';
import content from '../l10n/data'

import Helmet from 'react-helmet'

import Layout from './layout/BaseLayout'
import Header from './layout/Header'
import Nav from './layout/Nav'


// TODO: add related emojis
// TODO: add home page search bar also here for accessibility

export default function Emoji( data ) {
  // console.log(data)
  const iEmoji = data.pageContext
  return (
    <Layout>
        <Helmet title={iEmoji.name} />

        <Header />
        {/* <Nav /> */}

        <div id="main">
          <section id="content" className="main">
            <Twemoji>
                <center>
                  <h2>{iEmoji.name}</h2>
                  <h1>{iEmoji.emoji}</h1>
                </center>
            </Twemoji>
            <table>
              <tbody>
                <tr>
                  <td>{content.emojiName}</td>
                  <td>{iEmoji.name}</td>
                </tr>
                <tr>
                  <td>{content.emojiKeywords}</td>
                  <td>{iEmoji.keywords.join(', ')}</td>
                </tr>
                <tr>
                  <td>{content.emojiRaw}</td>
                  <td>{iEmoji.emoji}</td>
                </tr>

                <tr>
                  <td>{content.emojiGroup}</td>
                  <td>{iEmoji.group}</td>
                </tr>
                <tr>
                  <td>{content.emojiSkinToneSupport}</td>
                  <td>{iEmoji.skin_tone_support ? "✅": "❌"}</td>
                </tr>
                <tr>
                  <td>{content.emojiUnicodeVersion}</td>
                  <td>{iEmoji.unicode_version}</td>
                </tr>
                <tr>
                  <td>{content.emojiVersion}</td>
                  <td>{iEmoji.emoji_version}</td>
                </tr>
                <tr>
                  <td>📙</td>
                  <td><a href={iEmoji.emojipediaLink} target="_blank">{iEmoji.name} {content.emojiOnEmojipedia}</a></td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
    </Layout>
    )
}